import $ from "jquery";
import { TweenMax, TimelineMax, Linear } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export function init() {
	startParallax();
};

function startParallax() {

    var controller = new ScrollMagic.Controller({
	    globalSceneOptions: {
			offset: -1
	    }
	});

	if ($('.nav--main').length && $('[data-fixnav]').length) {
		new ScrollMagic.Scene({
			triggerElement: '[data-fixnav]',
			triggerHook: 0
		})
		.setClassToggle(".nav--main", "nav--fixed")
	    .addTo(controller);
	}

	if ($('.siteswapper').length && $('[data-fixnav]').length) {
		new ScrollMagic.Scene({
			triggerElement: '[data-fixnav]',
			triggerHook: 0
		})
		.setClassToggle(".siteswapper", "siteswapper--show")
		.addTo(controller);
	}

	$('.parallax').each(function() {

		var _this = this;

		var translateYIn  = $(this).attr('data-translateY-in'),
			translateYOut = $(this).attr('data-translateY-out'),
			translateXIn  = $(this).attr('data-translateX-in'),
			translateXOut = $(this).attr('data-translateX-out'),
			opacityIn = $(this).attr('data-opacity-in'),
			opacityOut = $(this).attr('data-opacity-out');

		if (typeof translateYIn  === 'undefined') translateYIn = 0;
		if (typeof translateYOut === 'undefined') translateYOut = 0;
		if (typeof translateXIn  === 'undefined') translateXIn = 0;
		if (typeof translateXOut === 'undefined') translateXOut = 0;
		if (typeof opacityIn  === 'undefined') opacityIn = 1;
		if (typeof opacityOut === 'undefined') opacityOut = 1;

		console.log(_this);
		var elHeight = $(_this).innerHeight();
		var windowHeight = $(window).height();
		var proportion = 100 * elHeight / windowHeight;
		if (proportion > 50) {
			proportion = 50;
		}
		if (elHeight < windowHeight / 3) {
			proportion = 35;
		}

		if (translateYOut !== 0 || translateXOut !== 0 || opacityOut !== 1) {

			new ScrollMagic.Scene({
				triggerElement: _this,
				triggerHook: 0,
				duration: proportion + "%"
			})
				.setTween(TweenMax.fromTo(_this.children, .5,
					{translateY: 0, translateX: 0, opacity: 1},
					{ease: 'none', translateY: translateYOut, translateX: translateXOut, opacity: opacityOut}
				))
				.addTo(controller);
		}

		if (translateYIn !== 0 || translateXIn !== 0 || opacityIn !== 1) {

			new ScrollMagic.Scene({
				triggerElement: _this,
				triggerHook: 1,
				duration: proportion + "%"
			})
				.setTween(TweenMax.fromTo(_this.children, .5,
					{translateY: translateYIn, translateX: translateXIn, opacity: opacityIn},
					{ease: 'none', translateY: 0, translateX: 0, opacity: 1}
				))
				.addTo(controller);
		}

	});
}
